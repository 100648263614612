<template>
  <v-navigation-drawer clipped temporary app v-model="menuActive">
    <v-list height="100%" :style="menuStyle" class="parentList">
      <router-link
        v-for="(item, index) in mainMenuListTop"
        :key="index"
        :to="{ name: item.routeName }"
        :event="''"
        @click.native.prevent="handleMenuClick($event, item)"
      >
        <v-list-item>
          <div v-html="item.icon" class="mr-3 mt-1"></div>
          <v-list-item-title :style="listItemStyle">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </router-link>
      <div class="list">
        <router-link
          v-for="(item, index) in mainMenuListBottom"
          :key="index"
          :to="{ name: item.routeName }"
          :event="''"
          @click.native.prevent="handleMenuClick($event, item)"
        >
          <v-list-item>
            <div v-html="item.icon" class="mr-3 mt-1"></div>
            <v-list-item-title :style="listItemStyle">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </router-link>
        <a :href="showUserGuide()" target="_blank" rel="noopener noreferrer">
          <v-list-item>
            <div v-html="usersGuide.icon" class="mr-3 mt-1"></div>
            <v-list-item-title :style="listItemStyle">
              {{ usersGuide.title }}
            </v-list-item-title>
          </v-list-item>
        </a>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import aws from "aws-sdk";
import { mapActions, mapMutations, mapState } from "vuex";
import amplitude from "amplitude-js"
import menuIcons from "@/assets/menuIcons/icons.js";
import BASE from "../store/api-endpoint.js";
export default {
  data: () => ({
    bucket: "billing-app-documentation",
    key: "SMArtX-Billing-for-Advisors-and-Home-Offices-Users-Guide.pdf"
  }),
  computed: {
    ...mapState("appNavigation", ["mainMenuActive"]),
    ...mapState("userConfig", ["colorTheme"]),
    menuStyle() {
      return {
        "background-color": this.colorTheme.menuBackground
      };
    },
    listItemStyle() {
      return {
        color: this.colorTheme.menuLetters
      };
    },
    mainMenuListTop() {
      let menu = [
        {
          title: "Fee Assignment",
          routeName: "FeeAssignment",
          icon: menuIcons.feeAssignment,
          action: () => this.showFeeAssignment()
        },
        {
          title: "Product Maintenance",
          routeName: "ProductMaintenance",
          icon: menuIcons.productMaintenance,
          action: () => this.showProductMaintenance()
        },
        {
          title: "Fee Review",
          routeName: "FeeResults",
          icon: menuIcons.feeReview,
          action: () => this.showFeeResults()
        },
        {
          title: "Inception",
          routeName: "InceptionWizard",
          icon: menuIcons.inception,
          action: () => this.showInceptionWizard()
        },
        {
          title: "Termination",
          routeName: "TerminationWizard",
          icon: menuIcons.termination,
          action: () => this.showTerminationWizard()
        },
        {
          title: "Download Portal",
          routeName: "DownloadPortal",
          icon: menuIcons.downloadPortal,
          action: () => this.showDownloadPortal()
        },
      ];
      return menu;
    },
    mainMenuListBottom() {
      let menu = [
        {
          title: "Profile",
          routeName: "Profile",
          icon: menuIcons.profile,
          action: () => this.showAccount()
        }
      ];
      return menu;
    },
    usersGuide() {
      return {
        title: "User's Guide",
        icon: menuIcons.usersGuide
      };
    },
    menuActive: {
      get() {
        return this.mainMenuActive;
      },
      set(newName) {
        this.setMainMenuActive(newName);
        return newName;
      }
    }
  },
  methods: {
    ...mapMutations("appNavigation", ["setMainMenuActive"]),
    ...mapActions("inceptions", ["showInceptionWizard"]),
    ...mapActions("terminations", ["showTerminationWizard"]),
    handleMenuClick(event, item) {
      // Prevent default navigation and execute custom action on normal left-click
      event.preventDefault();
      if (event.button === 0) {
        item.action();
      }
    },
    showFeeAssignment() {
      if (this.$route.matched[0].name !== "FeeAssignment") {
        this.$router.push({ name: "FeeAssignment" });
        amplitude.getInstance().logEvent("Tab Opened [Fee Assignment]");
      }
      this.closeMenu();
    },
    showProductMaintenance() {
      if (this.$route.matched[0].name !== "ProductMaintenance") {
        this.$router.push({ name: "ProductMaintenance" });
      }
      this.closeMenu();
    },
    showFeeResults() {
      if (
        this.$route.matched[0].name !== "FeeResults" &&
        this.$route.matched[0].name !== "AccountStatuses"
      ) {
        this.$router.push({ name: "FeeResults" });
        amplitude.getInstance().logEvent("Tab Opened [Fee Review]");
      }
      this.closeMenu();
    },
    showDownloadPortal() {
      if (this.$route.matched[0].name !== "DownloadPortal") {
        this.$router.push({ name: "DownloadPortal" });
        amplitude.getInstance().logEvent("Tab Opened [Download Portal]");
      }
      this.closeMenu();
    },
    showAccount() {
      if (this.$route.matched[0].name !== "Profile") {
        this.$router.push({ name: "Profile" });
        amplitude.getInstance().logEvent("Tab Opened [Account]");
      }
      this.closeMenu();
    },
    showUserGuide() {
      try {
        const s3 = new aws.S3();
        const params = { Bucket: this.bucket, Key: this.key };
        const URL = s3.getSignedUrl("getObject", params);
        amplitude.getInstance().logEvent("App Menu [Show File]", {
            "File": this.key
        });
        return URL;
      } catch (err) {
        const currentURL = window.location.host;
        if (currentURL == BASE.localhostUIHost) {
          console.warn("There are no AWS credentials on localhost");
          return;
        }
        console.error(err);
      }
    },
    closeMenu() {
      this.setMainMenuActive(false);
    }
  },
  beforeMount() {
    aws.config.setPromisesDependency();
    aws.config.update({
      accessKeyId: process.env.VUE_APP_ACCESS_KEY_ID,
      secretAccessKey: process.env.VUE_APP_SECRET_ACCESS_KEY,
      region: process.env.VUE_APP_REGION,
    });
  }
};
</script>
<style>
.parentList {
  position: relative;
}
.list {
  position: absolute;
  width: 100%;
  bottom: 65px;
  border-top: 2px solid #ffffff;
}
.v-list-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
